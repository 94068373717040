<template>
  <div>
    <zv-nav-bar :title="navtitle"></zv-nav-bar>
    <div class="news-detail">
      <div class="news-title">
        {{ newsTitle }}
      </div>
      <div class="news-time">
        {{ newsTime }}
      </div>
      <div class="news-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: '',
      newsTitle: '',
      newsTime: '',
      navtitle: '资讯',
    }
  },
  methods: {
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.newsTitle}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
  },
  created() {
    let _this = this
    this.navtitle = this.$route.query.itemtype ? this.$route.query.itemtype : '资讯'
    this.$axios({
      url: `${this.$route.params.url}`,
      methods: 'GET',
      params: {},
    }).then((res) => {
      if (res.status == 200) {
        _this.newsTitle = res.data.data.title
        _this.content = res.data.data.content
        _this.newsTime = res.data.data.publishDate
        this.setShare()
      }
    })
  },
}
</script>
<style lang="less" scoped>
.news-detail {
  padding: 0.13rem 0.53rem 0.5rem 0.53rem;
  /deep/ .news-title {
    color: #333333;
    font-size: 0.48rem;
    margin-bottom: 0.44rem;
    font-weight: bold;
  }
  /deep/ .news-time {
    font-size: 0.32rem;
    color: #999;
  }

  .news-content {
    font-size: 0.35rem;
    /deep/ p {
      font-size: 0.37rem !important;
      margin-bottom: 0.69rem;
    }
    /deep/ img {
      max-width: 100%;
    }
    /deep/ video {
      max-width: 100%;
    }
    /deep/ section {
      max-width: 100%;
    }
  }
}
</style>
